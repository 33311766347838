import { useEffect, useState } from "react";



import { Box } from "@chakra-ui/react";



import { debounce } from "~/utils/commons";


const AppBarWrapper = ({ children }: { children: React.ReactNode }) => {
  const [scroll, setScroll] = useState(false);

  const getScrollStatus = debounce(() => {
    setScroll(document.documentElement.scrollTop > 0);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", getScrollStatus);
    return () => window.removeEventListener("scroll", getScrollStatus);
  }, []);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 99,
        bg: "#FFF",
        paddingTop: `${scroll ? "calc(env(safe-area-inset-top) - 1px)" : "0px"}`,
      }}
      px={[1, 1, 0]}
      borderBottom={"1px solid"}
      borderColor={"brand.light-grey"}
      display={{ base: "flex", lg: "none" }}
    >
      {children}
    </Box>
  );
};

export default AppBarWrapper;